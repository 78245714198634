// Chakra imports
import {
    Flex,
    Icon,
    Select,
    Text,
    useColorModeValue,
    SimpleGrid,
    Stack,
    StackDivider,
  } from "@chakra-ui/react";
  // Custom components
  import React from "react";
  // Assets
  import {
    MdAcUnit,
    MdWifi,
    MdThermostat,
    MdOutlineLightbulb,
    MdOutlineLocationOn,
    MdCallReceived,
    MdDomain,
    MdElectricCar,
    MdSchool,
  } from "react-icons/md";
import Card from "../card/Card";
import Controller from "./components/Controller";
import { RiShareLine } from "react-icons/ri";
import { BiUserPlus } from "react-icons/bi";
import { AiOutlineDropbox } from "react-icons/ai";
import { IoIosSend } from "react-icons/io";
import { DROPZONE_COLOR, REQUEST_COLOR, RESPONSE_COLOR, SEND_COLOR } from "../../constants/constants";
import RoleListItem from "./RoleListItem";
import BlogPost from "./components/BlogPost";
  
  export default function Blogs({onRoleClick, ...rest}) {
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    return (
      <Card p='30px' h="full">
        <Text mb={{base: "20px", xl: "10mb", "2xl": "20px"}} fontSize={{base: 'lg', xl: "md", "2xl": "lg"}} lineHeight='100%' color={textColor} fontWeight='bold'>
          Blog Posts
        </Text>
        
        <Stack divider={<StackDivider />}>
        <BlogPost 
          mb={{base: '20px', xl: "10px", "2xl": "20px"}}
          title='Fidelity International improves customer experience using Amazon SageMaker'
          day='16'
          month='Nov'
        />
        <BlogPost
          mb={{base: '20px', xl: "10px", "2xl": "20px"}}
          title='Fidelity International improves customer experience using Amazon SageMaker'
          day='13'
          month='Nov'
        />
        <BlogPost
          title='Fidelity International improves customer experience using Amazon SageMaker'
          day='21'
          month='Nov'
        />
        </Stack>
      </Card>
    );
  }
