// Chakra imports
import { Box, Button, Stack, StackDivider, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "../../assets/img/dashboard/Project1.png";
import Project2 from "../../assets/img/dashboard/Project2.png";
import Project3 from "../../assets/img/dashboard/Project3.png";
// Custom components
import React from "react";
import Card from "../card/Card";
import PolicyAction from "./components/PolicyAction";

export default function PolicyActions({onSeeAll = () => null, ...rest}) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const buttonSize = useBreakpointValue({base: "md", xl: "sm", "2xl": "md"})
  const boxMaxHeight = useBreakpointValue({base: "300px", xl: "200px", "2xl": "300px"})
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} h="full" pb="5px">
      <Stack mb="10px" justifyContent={"space-between"} direction={"row"}>
        <Text
          fontSize={{base: "lg", xl: "md", "2xl": "lg"}}
          lineHeight="100%"
          color={textColorPrimary}
          fontWeight="bold"
        >
          Things to be done
        </Text>
        <Button variant='action' size={buttonSize} onClick={onSeeAll}>See all</Button>
      </Stack>
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='10px'>
        Here you can find more details about your projects. Keep you user
        engaged by providing meaningful information.
      </Text> */}
      <Stack
        w="full"
        css={{
          "&::-webkit-scrollbar": {
            height: "10px",
            width: "7px",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px rgba(128, 128, 128, 0)",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#E2E8F0",
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "#38a16998",
          },
        }}
        h="full"
        overflow={"auto"}
        maxH={boxMaxHeight}
        divider={<StackDivider />}
      >
        <PolicyAction
          image={Project1}
          ranking="1"
          link="#"
          title="Configuration Management Standard"
        />
        <PolicyAction
          image={Project2}
          ranking="2"
          link="#"
          title="Password Manager Policy"
        />
        <PolicyAction
          image={Project3}
          ranking="2"
          link="#"
          title="Auditing and Logging Standard"
        />
        <PolicyAction
          image={Project3}
          ranking="2"
          link="#"
          title="Auditing and Logging Standard"
        />
      </Stack>
    </Card>
  );
}
