// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { BsArrowRight } from "react-icons/bs";
// Assets
import { IoMdTime } from "react-icons/io";
import Card from "../../card/Card";

export default function ServiceItem(props) {
  const { current, icon, weekday, description, title, link, ...rest } = props;
  // Chakra Color Mode
  const miniCardCurrent = useColorModeValue("brand.600", "brand.400");
  const miniCardNonCurrent = useColorModeValue("transparent", "navy.700");
  const boxCurrent = useColorModeValue("brand.600", "navy.900");
  const boxNonCurrent = useColorModeValue("secondaryGray.300", "navy.800");
  const textCurrent = useColorModeValue("white", "white");
  const textNonCurrent = useColorModeValue("brand.500", "white");
  const textSecondaryCurrent = useColorModeValue("white", "white");
  const textSecondaryNonCurrent = useColorModeValue(
    "secondaryGray.600",
    "white"
  );

  const onClick = () => {
    window.open(link, "_blank")
  }
  return (
    <Card
      h="full"
      alignItems='center'
      p='6px'
      px="8"
      flexDirection="column"
      borderRadius='20px'
      justifyContent="center"
      bg={miniCardNonCurrent}
      borderColor="brand.500"
      borderWidth="1px"
      role="group"
      onClick={onClick}
      cursor={"pointer"}
      _hover={{
        bg: miniCardCurrent
      }}
      {...rest}>
        <Text
          textAlign={"center"}
          fontSize={{base: '2xl', xl: "lg", "2xl": "2xl"}}
          fontWeight='700'
          lineHeight={"30px"}
          mb={{base: "10px", xl: "0px", "2xl": "10px"}}
          _groupHover={{
            color: textCurrent
          }}
          color={textNonCurrent}>
          {title}
        </Text>
          <Text
            textAlign={"center"}
            fontSize={{base: 'sm', xl: "xs", "2xl": "sm"}}
            fontWeight='500'
            _groupHover={{
              color: textSecondaryCurrent
            }}
            color={textSecondaryNonCurrent}>
            {description}
          </Text>
    </Card>
  );
}
