import {
  Box,
  Link,
  Flex,
  useColorModeValue,
  Spinner,
  Stack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Grid,
  SimpleGrid,
  Button,
  Text,
  useDisclosure,
  useBreakpointValue,
  useBreakpoint,
  Skeleton,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import Card from "@/components/card/Card";
import MapBox from "@/components/maps/Map";
import WelcomeCard from "../components/dashboard2/WelcomeCard";
import Policy from "../components/dashboard2/Policy";
import Sypher from "../components/dashboard2/Sypher";
import NotificationBox from "../components/dashboard2/NotificationBox";
import Controller from "../components/Controller";
import { AiOutlineDropbox } from "react-icons/ai";
import { BiUser, BiUserPlus } from "react-icons/bi";
import { DROPZONE_COLOR, REQUEST_COLOR } from "../constants/constants";
import { SearchBar } from "../components/SearchBar";
import CustomActionIconButton from "../components/custom-styled-components/CustomActionIconButton";
import { MdFileOpen, MdSearch, MdSend } from "react-icons/md";
import PolicyActions from "../components/dashboard2/PolicyActions";
import SecurityCard from "../components/dashboard2/SecurityCard";
import PublishedDocumentsTable from "../components/dashboard2/MostVisitedTable";
import { tableColumnsMostVisited } from "../components/dashboard2/components/variables/tableColumnsMostVisited";
import tableDataMostVisited from "../components/dashboard2/components/variables/tableDataMostVisited";
import Roles from "../components/dashboard2/Roles";
import TasksToBeDone from "../components/dashboard2/components/TasksToBeDone";
import DocumentsModal from "../components/dashboard2/components/DocumentsModal";
import RoleModal from "../components/dashboard2/components/RoleModal";
import Services from "../components/dashboard2/Services";
import BlogPost from "../components/dashboard2/components/BlogPost";
import Blogs from "../components/dashboard2/Blogs";
import Docs from "../components/dashboard2/Docs";
import News from "../components/dashboard2/News";
import { IoMdClock } from "react-icons/io";
import { useAuth } from "../context/auth";

const staticMapData = [
  [76.78890228271484, 30.73390007019043],
  [-79.51280212402344, 43.85029983520508],
];

const HISTORY_TYPE = {
  SEND: "SEND",
  REQUEST: "REQUEST",
  DROPZONE: "DROPZONE",
};

const NOTIFICATIONS = [
  {
    text: "Document waiting for approval!",
    date: "2023-11-27T16:03:52Z",
    icon: MdFileOpen,
  },
  {
    text: "Pending review!",
    date: "2023-11-27T16:03:52Z",
    icon: IoMdClock,
  },
  {
    text: "Data accessed for send.",
    date: "2023-11-27T16:03:52Z",
    icon: MdSend,
  },
  {
    text: "New Document is published.",
    date: "2023-11-27T16:03:52Z",
    icon: MdFileOpen,
  },
  {
    text: "New Role is assigned.",
    date: "2023-11-27T16:03:52Z",
    icon: BiUser,
  },
  {
    text: "Document waiting for approval!",
    date: "2023-11-27T16:03:52Z",
    icon: MdFileOpen,
  },
  {
    text: "Pending review!",
    date: "2023-11-27T16:03:52Z",
    icon: IoMdClock,
  },
  {
    text: "Data accessed for send.",
    date: "2023-11-27T16:03:52Z",
    icon: MdSend,
  },
  {
    text: "New Document is published.",
    date: "2023-11-27T16:03:52Z",
    icon: MdFileOpen,
  },
  {
    text: "New Role is assigned.",
    date: "2023-11-27T16:03:52Z",
    icon: BiUser,
  }
]

export default function Dashboard2() {
  const { user } = useAuth();
  const {
    isOpen: isTasksModalOpen,
    onOpen: onTasksModalOpen,
    onClose: onTasksModalClose,
  } = useDisclosure();
  const {
    isOpen: isDocumentsModalOpen,
    onOpen: onDocumentsModalOpen,
    onClose: onDocumentsModalClose,
  } = useDisclosure();
  const {
    isOpen: isRoleModalOpen,
    onOpen: onRoleModalOpen,
    onClose: onRoleModalClose,
  } = useDisclosure();
  const [selectedRole, setSelectedRole] = useState();
  const [isLoading, setLoading] = useState(false);
  const [mapData, setMapData] = useState([]);
  const [nonCompliantUsers, setNonCompliantUsers] = useState([]);
  const [responseHistoryEnabled, setResponseHistoryEnabled] = useState(true);
  const [dropzoneResponseHistoryEnabled, setDropzoneResponseHistoryEnabled] =
    useState(true);
  const [date, setDate] = useState(new Date());
  const iconBg = useColorModeValue("secondaryGray.300", "navy.700");
  const iconColor = useColorModeValue("brand.500", "white");
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  let menuBg = useColorModeValue("white", "navy.800");

  const renderNotificationBox = (display) => {
    let comp = (
      <Box
        mb={{ base: "20px", lg: "20px" }}
        maxH={{ base: "100%", xl: "100%", "2xl": "100%" }}
        height={{ base: "max-content", md: "100%" }}
        display={display}
      >
        <NotificationBox
          // hasNext={next || hasMoreDropzoneResponses}
          // fetchNext={fetchNext}
          // loading={isLazyLoading}
          data={NOTIFICATIONS}
          maxH={{ base: "auto", lg: "100%" }}
          minH={{ base: "auto", lg: "100%" }}
          overflow="hidden"
          _hover={{
            overflow: "auto",
          }}
        />
        {/* <DocSideBar pr={{ base: "auto", lg: "31px" }} maxH={{ base: "auto", lg: "834px" }} minH={{ base: "auto", lg: "834px" }} overflow="hidden" _hover={{
        overflow: "auto",
      }} /> */}
      </Box>
    );
    return comp;
  };

  const onSeeAllTasks = useCallback(() => {
    onTasksModalOpen();
  }, []);

  const onSeeAllDocuments = useCallback(() => {
    onDocumentsModalOpen();
  }, []);

  const onRoleClick = (role) => {
    setSelectedRole(role);
    onRoleModalOpen();
  };

  const showForSmallScreen = useBreakpointValue({
    base: false,
    xl: true,
    "2xl": false,
  });

  const isXL = useBreakpointValue({
    base: false,
    xl: true,
    "2xl": false
  })
  return (
    <>
      {isLoading ? (
        <Flex height={"100vh"} justifyContent={"center"} alignItems="center">
          <Spinner />
        </Flex>
      ) : (
        <Box p="6" maxW="100%" h="full">
          <Flex w="full" justify={"space-between"} align={"center"}>
            {user?.first_name?<Link
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              {`Welcome Back, ${user?.first_name}!`}
            </Link>:
            <Skeleton height='20px' w="80px" />}
            <Flex
              w={"35%"}
              alignItems="center"
              flexDirection="row"
              bg={menuBg}
              flexWrap={{ base: "wrap", md: "nowrap" }}
              p="10px"
              borderRadius="30px"
              boxShadow={shadow}
            >
              <SearchBar
                enableLeftIcon
                mb={{ base: "10px", md: "unset" }}
                me="10px"
                // isDisabled={searching}
                // onKeyDown={onEnter}
                borderRadius="30px"
                borderColor="secondaryGray.200"
                placeholder="Ask me anything..."
                // onChange={(e) => setQuestion(e.currentTarget.value)}
              />
              <CustomActionIconButton
                borderRadius="20px"
                variant="outline"
                // isLoading={searching}
                // onClick={fetchAnswer}
                icon={<MdSearch />}
              />
            </Flex>
          </Flex>
          <Grid
            pt={{ base: "130px", md: "80px", xl: "20px" }}
            mb="20px"
            gridTemplateColumns={{ base: "2.6fr 1fr", "2xl": "3fr 1fr" }}
            gap={{ base: "20px", xl: "20px" }}
            display={{ base: "block", lg: "grid" }}
          >
            <Box gridArea="1 / 1 / 2 / 2">
              <Flex gap="20px" mb={isXL?"0px":"20px"}>
                <Box flex={"1"}>
                  <WelcomeCard />
                </Box>
                {!showForSmallScreen && <Box flex={"1"}>
                  <Services />
                </Box>}
                {!showForSmallScreen && (
                  <Box flex={"2"}>
                    <PolicyActions onSeeAll={onSeeAllTasks} />
                  </Box>
                )}
                {showForSmallScreen && <Stack spacing={"20px"}>
                    <Services /> 
                    <PolicyActions onSeeAll={onSeeAllTasks} />
                </Stack>}
              </Flex>
              {!isXL && <SimpleGrid columns={3} gap="20px">
            <Blogs />
            <Docs />
            <News />
            </SimpleGrid>}
            </Box>
            <Box gridArea="1 / 2 / 2 / 3">
              {renderNotificationBox({ base: "none", md: "block" })}
            </Box>
          </Grid>
          {isXL && <SimpleGrid columns={3} gap="20px">
            <Blogs />
            <Docs />
            <News />
            </SimpleGrid>}
          <TasksToBeDone
            isOpen={isTasksModalOpen}
            onClose={onTasksModalClose}
          />
          <DocumentsModal
            isOpen={isDocumentsModalOpen}
            onClose={onDocumentsModalClose}
          />
          <RoleModal
            data={selectedRole}
            isOpen={isRoleModalOpen}
            onClose={onRoleModalClose}
          />
        </Box>
      )}
    </>
  );
}
