import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import IconBox from "../../icons/IconBox";
import { IoDocumentOutline } from "react-icons/io5";

export default function DocsItem({ title, link, description }) {
  const secondaryText = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const iconBoxSize = useBreakpointValue({
    base: "66px",
    xl: "46px",
    "2xl": "66px",
  });
  const iconSize = useBreakpointValue({
    base: "32px",
    xl: "24px",
    "2xl": "32px",
  });
  return (
    <Flex justifyContent={"space-between"} align={"center"} gap={"5px"}>
      <Flex
        me="20px"
        direction="row"
        align="center"
        justify="center"
        borderRadius="15px"
      >
        <IconBox
          transition="0.2s linear"
          me={{base: "0px", xl: "10px", "2xl": "0px"}}
          h={iconBoxSize}
          w={iconBoxSize}
          icon={
            <Icon
              transition="0.2s linear"
              as={IoDocumentOutline}
              color={"brand.500"}
              h={iconSize}
              w={iconSize}
            />
          }
        />
        <Box>
          <Link fontWeight="500" color={brandColor} href={link} fontSize="md">
            {title}
          </Link>
          <Text mb="2px" fontSize="md" fontWeight="500" color={secondaryText}>
            {description}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
