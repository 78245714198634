// Chakra imports
import { SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import Card from "../card/Card";
import ServiceItem from "./components/ServiceItem";
export default function Services(props) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <SimpleGrid h="full" columns={2} spacing={"20px"} {...rest}>
      {/* <Text fontSize='2xl' fontWeight='700' color={textColor}>
        Services
      </Text>
      <Text fontSize='md' fontWeight='500' color='secondaryGray.600' mb='30px'>
        See your products in our timelines:
      </Text> */}
      <ServiceItem
        title='IAM'
        icon='I'
        link='/iam'
        description='Manage roles and permissions of your users.'
      />
      <ServiceItem
        mb='16px'
        title='Sypher'
        icon='S'
        link='/sypher'
        description='Send data securely using post quantum encryption.'
        current
      />
      <ServiceItem
        mb='16px'
        title='Policy Manager'
        icon='P'
        link='/policy'
        description='Modern way to manage your company policies.'
      />
      <ServiceItem
        mb='16px'
        title='Asset Intelligence'
        icon='A'
        link='/asset-intelligence'
        description='AI powererd asset intelligence'
      />
    </SimpleGrid>
  );
}
