// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React from "react";
// Assets
import { MdEdit, MdEditDocument } from "react-icons/md";
import Card from "../../card/Card";
import IconBox from "../../icons/IconBox";
import { IoDocumentOutline } from "react-icons/io5";

export default function PolicyAction(props) {
  const { title, ranking, link, image, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  const iconBoxSize = useBreakpointValue({
    base: "66px",
    xl: "46px",
    "2xl": "66px"
  })
  const iconSize = useBreakpointValue({
    base: "32px",
    xl: "24px",
    "2xl": "32px"
  })
  const buttonSize = useBreakpointValue({
    base: "md",
    xl: "xs",
    "2xl": "md"
  })
  return (
    <Flex bg={bg} {...rest} p="0px">
      <Flex w="full" align="center" justify={"space-between"} direction={{ base: "column", md: "row" }}>
        <IconBox
          transition="0.2s linear"
          h={iconBoxSize}
          w={iconBoxSize}
          icon={
            <Icon
              transition="0.2s linear"
              as={IoDocumentOutline}
              color={"brand.500"}
              h={iconSize}
              w={iconSize}
            />
          }
        />
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight="500"
            fontSize={{base: "md", xl: "sm", "2xl": "md"}}
            mb={{base: "4px", xl: "1px", "2xl": "4px"}}
          >
            {title}
          </Text>
          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize={{base: "sm", xl: "xs", "2xl": "sm"}}
            me="4px"
          >
            Effective Date:{" "}
            <Text as="span" fontWeight="500" color={brandColor} fontSize={{base: "sm", xl: "xs", "2xl": "sm"}}>
              3 May 2024
            </Text>
          </Text>
        </Box>
        <Button
          variant="action"
          size={buttonSize}
          fontWeight="700"
          ms="auto"
          me="5px"
        >
          Acknowledge
        </Button>
      </Flex>
    </Flex>
  );
}
