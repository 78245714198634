import { Flex, Link, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function BlogPost({title, link, month, day}) {
    const secondaryText = "gray.400"
    const brandColor = useColorModeValue("brand.500", "white");
  return (
    <Flex justifyContent={"space-between"} align={"center"} gap={"5px"}>
      <Flex
        me="20px"
        direction="column"
        align="center"
        justify="center"
        w="77px"
        h="77px"
        borderRadius="15px"
      >
        <Text
          mb="2px"
          fontSize="md"
          fontWeight="500"
          color={secondaryText}
        >
          {month}
        </Text>
        <Text
          lineHeight="100%"
          fontSize="34px"
          fontWeight="700"
          color={secondaryText}
        >
          {day}
        </Text>
      </Flex>
      <Flex flex={1}>
        <Link fontWeight='500' color={brandColor} href={link} fontSize='md'>
            {title}
        </Link>
      </Flex>
    </Flex>
  );
}
