import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import "@blocknote/core/style.css";
import CustomFormLabel from "../../custom-styled-components/CustomFormLabel";

const INIT_FORM_DATA = {
  name: "",
  data: "",
};

export default function RoleModal({
  data,
  onSubmit = (values) => null,
  onClose = () => null,
  isOpen = false,
  ...rest
}) {
  console.log(data, data?.data);
  const [animateOnFirstRender, setAnimateOnFirstRender] = useState(true);
  const [formData, setFormData] = useState(INIT_FORM_DATA);
  const editorData = useMemo(
    () => (data?.data?.role_data ? JSON.parse(data?.data?.role_data) : []),
    [data]
  );
  const editor = useBlockNote({
    editable: false
  });

  useEffect(() => {
    editor.slashMenu.emit("update", { referencePos: {} });
    if (data) {
      setFormData({
        name: data?.data?.role_name,
        role_data: editorData,
      });
      if (data?.data?.role_data) {
        editor.replaceBlocks(editor.topLevelBlocks, []);
        editor.replaceBlocks(editor.topLevelBlocks, editorData);
      } else {
        editor.replaceBlocks(editor.topLevelBlocks, []);
      }
    } else {
      setFormData(INIT_FORM_DATA);
      editor.replaceBlocks(editor.topLevelBlocks, []);
    }
  }, [data]);

  return (
    <Modal
      onClose={onClose}
      size={"6xl"}
      isOpen={isOpen}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modal Title</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CustomFormLabel>{formData.name}</CustomFormLabel>
          <Box
            borderWidth={"1px"}
            borderColor={"secondaryGray.200"}
            borderRadius={"12px"}
            h={"100%"}
            pl="20px"
            mb="30px"
            minH="200px"
          >
            <BlockNoteView editor={editor} theme={"light"} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
