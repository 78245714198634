import React from "react";
import Card from "@/components/card/Card";
import { Button, Text } from "@chakra-ui/react";
import home from "../../assets/img/dashboard/home.png";
import CustomText from "../custom-styled-components/CustomText";

export default function WelcomeCard() {
  return (
    <Card
      bgSize="cover"
      w=""
      minH={{ base: "310px", md: "100%" }}
      bgImage={home}
    >
      {/* <CustomText color={"#fff"} fontWeight={"bold"} fontSize={{base: "4xl", xl: "2xl", "2xl": "4xl"}}>
        {"Welcome "}
        <br></br>
        {"Back!"}
      </CustomText> */}
    </Card>
  );
}
