// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "../../assets/Project1.png";
import Project2 from "../../assets/Project2.png";
import Project3 from "../../assets/Project3.png";
import React from "react";
import NewsItem from "./components/NewsItem";
import Card from "../card/Card";

export default function News(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  return (
    <Card p='30px' h="full">
    <Text mb={{base: "20px", xl: "10mb", "2xl": "20px"}} fontSize={{base: 'lg', xl: "md", "2xl": "lg"}} lineHeight='100%' color={textColorPrimary} fontWeight='bold'>
      News
    </Text>
      <NewsItem
        mb="20px"
        image={Project1}
        ranking="1"
        link="#"
        title="Technology behind the Blockchain"
      />
      <NewsItem
        mb="20px"
        image={Project2}
        ranking="2"
        link="#"
        title="Greatest way to a good Economy"
      />
      <NewsItem
        image={Project3}
        ranking="3"
        link="#"
        title="Most essential tips for Burnout"
      />
    </Card>
  );
}
